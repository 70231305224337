<template>
  <Intersect
    v-editable="blok"
    v-bind="{ ...(blok.id ? { id: blok.id } : {}) }"
    class="relative @container"
    :class="{
      'h-full': isHundredPercent,
      'h-[30rem] sm:h-screen': isFullHeight,
      'h-[25rem]': isSmallHeight,
      'h-[30rem]': isMediumHeight,
      'h-[35rem]': isLargeHeight,
      'h-[40rem]': isXtraLargeHeight,
    }"
    :threshold="0.5"
    @enter="intersectPromotionView"
  >
    <FimPicture
      v-if="image"
      class="z-0"
      :mobile="image.srcMobile"
      :desktop="image.srcDesktop"
      :focus-mobile="image.focusMobile"
      :focus-desktop="image.focusDesktop"
      :ratio-mobile="ratioMobile || image.mobile_ratio"
      :ratio-desktop="ratioDesktop || image.desktop_ratio"
      :preload="preloadMedia"
      :sizes="imageSizes"
      provider="storyblok"
      layout="fill"
      :object-fit="image.objectFit ?? 'cover'"
      :object-position="image.objectPosition || 'center'"
      :modifiers="{ smart: true }"
      :widths="pictureWidths"
      :style="{ backgroundColor }"
    />
    <div
      class="size-full"
      :class="[
        gradientPosition,
        {
          'gradient-light': isLight && hasGradient,
          'gradient-dark': isDark && hasGradient,
        },
      ]"
    >
      <div
        class="content box absolute bottom-4 left-4 flex flex-col items-start"
        :class="[
          {
            '@md:bottom-auto @md:left-1/2 @md:top-1/2 @md:-translate-x-1/2 @md:-translate-y-1/2':
              !blok.position || blok.position === 'center',
            '@md:bottom-auto @md:left-1/2 @md:top-8 @md:-translate-x-1/2':
              blok.position === 'top',
            '@md:bottom-8 @md:left-1/2 @md:-translate-x-1/2':
              blok.position === 'bottom',

            'items-end text-right @md:inset-x-8 @md:bottom-auto @md:top-1/2 @md:-translate-y-1/2':
              blok.position === 'right',
            'items-end text-right @md:inset-x-8 @md:bottom-auto @md:top-8':
              blok.position === 'top-right',
            'items-end text-right @md:inset-x-8 @md:bottom-8':
              blok.position === 'bottom-right',

            '@md:inset-x-8 @md:bottom-auto @md:top-1/2 @md:-translate-y-1/2':
              blok.position === 'left',
            '@md:inset-x-8 @md:bottom-auto @md:top-8':
              blok.position === 'top-left',
            '@md:inset-x-8 @md:bottom-8': blok.position === 'bottom-left',
          },
        ]"
      >
        <div
          class="flex flex-col items-start"
          :class="[
            ...contentWidthClasses,
            {
              'items-center': blok?.text_align === 'text-center',
              'items-start': blok?.text_align === 'text-left',
              'items-end': blok?.text_align === 'text-right',
            },
          ]"
        >
          <CmsButton
            v-if="link && hasLink && !keepHeadline && headline"
            class="mt-5 block md:hidden"
            is-link
            :blok="link"
            :theme="blok.theme"
            :type="link.cta_variant"
            @mousedown="trackClick"
          >
            {{ headline.text }}
          </CmsButton>
          <Box
            :variant="boxVariant"
            :class="{
              'hidden md:flex': hasLink && !keepHeadline,
            }"
          >
            <Headline
              v-if="headline"
              v-bind="{ ...headline }"
              class="tracking-[-0.3px]"
              :class="headlineClass"
            >
              {{ headline.text }}
            </Headline>
          </Box>
          <Box
            v-if="text"
            :variant="boxVariant"
            class="my-6 text-xl"
            :class="{
              'text-white': isDark && !hasTextBackground,
              'text-black': isLight && !hasTextBackground,
              [blok?.text_align as string]: true,
            }"
          >
            <RichTextRenderer :document="text.body" />
          </Box>

          <CmsButton
            v-if="link && hasLink"
            :id="'teaser-' + blok._uid"
            class="mt-5"
            :class="{
              'hidden md:flex': hasLink && !keepHeadline,
            }"
            is-link
            :blok="link"
            :theme="blok.theme"
            :type="link.cta_variant"
            @mousedown="trackClick"
          >
            {{ link.cta_label }}
          </CmsButton>
        </div>
      </div>
    </div>
  </Intersect>
</template>

<script setup lang="ts">
import type { SbTeaser } from '../types/storyblok.gen'

const props = defineProps({
  preloadMedia: {
    type: Boolean as PropType<boolean>,
    default: false,
  },
  blok: {
    type: Object as PropType<SbTeaser>,
    default: () => {},
  },
  keepHeadline: {
    type: Boolean as PropType<boolean>,
    default: false,
  },
  imageSizes: {
    type: String as PropType<string>,
    default: undefined,
  },
  pictureWidths: {
    type: Object as PropType<object>,
    default: () => ({
      xs: 320,
      sm: 640,
      md: 960,
      xl: 1280,
    }),
  },
  ratioMobile: {
    type: [Number, String] as PropType<number | string>,
    default: undefined,
  },
  ratioDesktop: {
    type: [Number, String] as PropType<number | string>,
    default: undefined,
  },
})

const { trackButtonClick, trackPromotionView, trackPromotionClick } =
  await useTrackingEvents()

const trackClick = (event: Event) => {
  const element = event?.target as HTMLButtonElement
  trackButtonClick(element)

  if (isStoryblokTrackingContent(props.blok)) {
    trackPromotionClick(props.blok)
  }
}

const hasTextBackground = computed(
  () => (props?.blok?.variant ?? 'text-box') === 'text-box',
)

const image = computed(() => parseImageRef(props.blok.image_ref))
const backgroundColor = computed(() =>
  parseColor(image.value?.background_color),
)
const headline = computed(() =>
  parseHeadlineRef(props.blok.headline_ref, {
    tag: HeadlineTag.H2,
    look: HeadlineLook.H1,
  }),
)
const link = computed(() => getLinkRef(props.blok.cta_ref))
const text = computed(() => parseTextRef(props.blok.text_ref))

const hasLink = computed(() => link.value?.cta_url && link.value?.cta_label)

const isDark = computed(() => props.blok.theme === 'dark')
const isLight = computed(() => props.blok.theme === 'light')

const headlineClass = computed(() => {
  return {
    'text-white': isDark.value && !hasTextBackground.value,
    'text-black': isLight.value && !hasTextBackground.value,
  }
})

const boxVariant = computed(() => {
  if ((props?.blok?.variant ?? 'text-box') === 'text-box') {
    return props.blok.theme === 'light' ? 'secondary' : 'primary'
  }

  return 'ghost'
})
const hasGradient = computed(
  () => props.blok.variant === 'gradient' && props.blok.show_gradient,
)
const gradientPosition = computed(() => {
  const pos = props.blok?.position

  return props.blok.show_gradient ? `gradient-bottom md:gradient-${pos}` : ''
})

// @todo refactor
const isHundredPercent = computed(() => props.blok.height === '100%')
const isFullHeight = computed(() => props.blok.height === 'full')
const isSmallHeight = computed(() => props.blok.height === 'sm')
const isMediumHeight = computed(() => props.blok.height === 'md')
const isLargeHeight = computed(() => props.blok.height === 'lg')
const isXtraLargeHeight = computed(() => props.blok.height === 'xl')

const contentWidthClasses = computed(() =>
  ['w-full', '@sm:w-1/2', '@lg:w-1/3'].slice(
    0,
    parseFloat(props.blok.max_columns ?? '1'),
  ),
)

const intersectPromotionView = (
  _: IntersectionObserverEntry,
  stop: () => void,
) => {
  if (isStoryblokTrackingContent(props.blok)) {
    trackPromotionView(props.blok)
  }
  stop()
}
</script>

<style scoped>
.content {
  max-width: calc(100% - 2rem);
  max-height: calc(100% - 2rem);

  /*
  i get the idea that the image should be visible in the background, but it also pushes content out of the box...
  @media (width >= 28rem) {
    max-height: calc(50% - 2rem);
  }
  */
}

.gradient-top-left::before,
.gradient-top::before,
.gradient-top-right::before,
.gradient-left::before,
.gradient-center::before,
.gradient-right::before,
.gradient-bottom-left::before,
.gradient-bottom::before,
.gradient-bottom-right::before {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  height: 18rem;
  width: 100%;
  max-width: 56rem;
  background: linear-gradient(
    var(--color-text-gradient-deg, 180deg),
    var(--color-text-gradient-start, hsl(0deg 0% 100% / 0)),
    var(--color-text-gradient-end, hsl(0deg 0% 100% / 0.45))
  );
}

.gradient-top-left::before,
.gradient-top::before,
.gradient-top-right::before {
  bottom: auto;
  top: 0;

  --color-text-gradient-deg: 0deg;
}

.gradient-light::before {
  --color-text-gradient-start: hsl(0deg 0% 100% / 0);
  --color-text-gradient-end: hsl(0deg 0% 100% / 0.45);
}

.gradient-dark::before {
  --color-text-gradient-start: hsl(0deg 0% 100% / 0);
  --color-text-gradient-end: rgb(0 0 0 / 0.75);
}

@media (width >= 40rem) {
  .gradient-top-left::before,
  .gradient-left::before,
  .gradient-bottom-left::before {
    height: 100%;
    width: 60%;
    left: 0;
    top: 0;
    bottom: 0;

    --color-text-gradient-deg: 270deg;
  }

  .gradient-top-right::before,
  .gradient-right::before,
  .gradient-bottom-right::before {
    height: 100%;
    width: 60%;
    inset: 0 0 0 auto;

    --color-text-gradient-deg: 90deg;
  }

  .gradient-top::before {
    height: 60%;
    max-width: none;
  }

  .gradient-bottom::before {
    height: 60%;
    max-width: none;
  }

  .gradient-center::before {
    width: 80%;
  }
}
</style>
